import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import InView from '../../components/Shared/InView';
import SimpleAnimation from '../../styles/Components/SimpleAnimation';
import Styles from './styles';
import theme from '../../styles/theme';
//TODO: Remove happy houses
// import Skins from './Skins';
import Video from '../../components/Shared/Video';
import Picture from '../Shared/Picture';
import createClassList from '../../util/createClassList';
import noop from '../../util/noop';
import Modal from '../Shared/Modal';
import Button from '../../components/Shared/Button';
import PlayIcon from '../../components/Shared/Icons/playFilled';

const imageSources = [
  { media: '(max-width: 640px)', params: '?w=640' },
  { media: '(max-width: 1000px)', params: '?w=1000' },
  { media: '(max-width: 1400px)', params: '?w=1400' },
];

const possibleSkins = {
  //TODO: Remove happy houses
  //happyHomeSkin: 'Happy Home Skin',
  none: null,
};

const possibleVideoSources = {
  wistia: 'Wistia',
  widen: 'Widen',
  youtube: 'YouTube',
  vimeo: 'Vimeo',
  qzzr: 'Qzzr',
  none: 'Not Applicable',
};

const VideoImageSection = ({
  skin = null,
  imageSrc = null,
  imageDescription = null,
  embeddedContentID = null,
  mediaType = null,
  heading = '',
  subHeading = '',
  videoSource = '',
  thumbnailSource = '',
  thumbnailAlt = '',
  onPlay = noop,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const Skin = useMemo(() => {
    switch (skin) {
      //TODO: Remove happy houses
      // case possibleSkins.happyHomeSkin:
      //   return Skins.HappyHomes;
      default:
        return () => null;
    }
  }, [skin]);

  const showVideo = useMemo(
    () => videoSource && embeddedContentID,
    [videoSource, embeddedContentID]
  );

  const contentClassList = useMemo(() => {
    const classes = [];

    if (skin === possibleSkins.happyHomeSkin) {
      classes.push('video-image-section__content--happy-homes-skin');
    }

    return classes;
  }, [skin]);

  const handlePlayClick = () => {
    onPlay();
    setModalOpen(true);
  };

  return (
    <InView>
      <Styles>
        <div className='video-image-section'>
          <SimpleAnimation.h2 delay={0.2} animateWhen={heading}>
            {heading}
          </SimpleAnimation.h2>
          <SimpleAnimation.p delay={0.4} animateWhen={subHeading}>
            {subHeading}
          </SimpleAnimation.p>
          <SimpleAnimation.div
            className={createClassList('video-image-section__content', [
              ...contentClassList,
            ])}
            delay={0.6}
            animateWhen={showVideo || imageSrc}
          >
            {showVideo ? (
              <div className='video-image-thumbnail'>
                <Picture
                  src={`${thumbnailSource}?w=626`}
                  alt={thumbnailAlt}
                  sources={[
                    {
                      media: `(min-width: ${theme.breakpoints.desktop})`,
                      params: '?w=626',
                      srcSet: thumbnailSource,
                    },
                    {
                      media: `(min-width: ${theme.breakpoints.tablet})`,
                      params: '?w=512',
                      srcSet: thumbnailSource,
                    },
                    {
                      media: `(max-width: ${theme.breakpoints.tablet})`,
                      params: '?w=300',
                      srcSet: thumbnailSource,
                    },
                  ]}
                />
                <Button
                  onClick={handlePlayClick}
                  appearance='light'
                  className='play-button'
                >
                  <PlayIcon />
                  <p>Watch video</p>
                </Button>
              </div>
            ) : (
              <Picture
                src={imageSrc}
                alt={imageDescription}
                isResponsive={true}
                sources={imageSources}
              />
            )}
          </SimpleAnimation.div>
        </div>
        <Skin />
      </Styles>
      {showVideo && modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <Video
            content={embeddedContentID}
            source={videoSource}
            autoplay={true}
          />
        </Modal>
      )}
    </InView>
  );
};

VideoImageSection.propTypes = {
  skin: PropTypes.oneOf(Object.values(possibleSkins)),
  imageSrc: PropTypes.string,
  imageDescription: PropTypes.string,
  embeddedContentID: PropTypes.string,
  mediaType: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  videoSource: PropTypes.oneOf(Object.values(possibleVideoSources)),
};

export default VideoImageSection;
