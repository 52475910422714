import styled from 'styled-components';
import buttonColor from '../../styles/contentful/buttonColor';

const ButtonStyles = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  text-decoration: none;
  padding: 1rem 1.375em;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  width: ${props => (props.fullwidth ? '100%' : 'auto')};
  font-weight: 600;
  font-family: 'source-sans-pro';
  line-height: 19px;
  font-size: ${props => (props.size === 'large' ? '16px' : '14px')};
  color: ${props =>
    props.outline
      ? props.appearance === 'dark'
        ? props.theme.colors.claytonBlue
        : props.theme.colors.white
      : props.appearance === 'dark'
      ? props.theme.colors.white
      : props.theme.colors.darkBlue};
  border: solid 1px
    ${props =>
      props.appearance === 'dark'
        ? props.theme.colors.claytonBlue
        : props.theme.colors.white};
  background: ${props =>
    props.outline
      ? 'transparent'
      : props.appearance === 'dark'
      ? props.theme.colors.claytonBlue
      : props.theme.colors.white};

  > * {
    margin: 0;

    &:nth-child(n + 2) {
      margin-left: 0.5rem;
    }
  }

  svg {
    color: ${props => props.theme.colors.vmfGreen2};
  }

  &:not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      color: ${props =>
        props.outline
          ? (props.hoverAppearance && props.hoverAppearance === 'dark') ||
            props.appearance === 'dark'
            ? props.theme.colors.white
            : props.theme.colors.darkBlue
          : (props.hoverAppearance && props.hoverAppearance === 'dark') ||
            props.appearance === 'dark'
          ? props.theme.colors.claytonBlue
          : props.theme.colors.white};
      border: solid 1px
        ${props =>
          (props.hoverAppearance && props.hoverAppearance === 'dark') ||
          props.appearance === 'dark'
            ? props.theme.colors.claytonBlue
            : props.theme.colors.white};
      background: ${props =>
        props.outline
          ? (props.hoverAppearance && props.hoverAppearance === 'dark') ||
            props.appearance === 'dark'
            ? props.theme.colors.claytonBlue
            : props.theme.colors.white
          : 'transparent'};
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: initial;
  }

  ${props =>  props.contentfulColors ? buttonColor(props.contentfulColors)[props.inverse ? 'inverse' : 'css'] : ''}
`;

export default ButtonStyles;
